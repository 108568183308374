















































import { Component, Inject, Watch, Vue } from "vue-property-decorator";

import Card from "@/components/material/Card.vue";
import { TableHeader } from "@/components/layout/models/table.d";
import { EntityHistory } from "@/models/library-maintenance.d";
import HistoryService from "@/services/history-service";
import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import LocalState from "@/store/modules/local-state-module";
import { TableOptions, VDataTableOptions, SnackbarOptions } from "@/models/form";
import EcSnackBar from "common-components/src/components/form/ec-snack-bar.vue";
import axios, { CancelTokenSource } from "axios";
import { Query } from "@/models/types";
import { formatSort } from "@/components/helper/sortHelpers"

const appState = getModule(AppState);
const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    EcSnackBar,
  },
})
export default class HistoryListView extends Vue {
  @Inject() HistoryService!: HistoryService;

  options: TableOptions = {
    link: false,
  }

  headers: TableHeader[] = [];

  items: EntityHistory[] = [];

  totalItems = 0;

  loading = false;

  page = 1;

  size = localState.defaultPageSize;

  sort: string[] = ["time,desc"];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  cancellationToken!: CancelTokenSource;

  entityType = '';
  entityId = '';

  get numberOfPages() {
    if (this.size === 0) return 1;
    return Math.max(1, Math.ceil(this.totalItems / this.size));
  }

  async mounted(): Promise<void> {
    this.headers = [
      {
        sortable: true,
        sortDirection: "",
        text: "Time",
        value: "time"
      },
      {
        sortable: true,
        sortDirection: "",
        text: "User",
        value: "user"
      },
      {
        sortable: false,
        sortDirection: "",
        text: "Activity",
        value: "activity"
      }
    ];
  }

  get apiFault() {
    return appState.apiFault;
  }

  get sortBy() { return this.sort.map((x) => x.split(',')[0]); }

  get sortDesc() { return this.sort.map((x) => x.split(',')[1] === 'desc'); }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    const { page = 1, size = this.size, sort = this.sort } = this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = formatSort(sort);

    this.entityType = this.$route?.meta?.entityType;
    this.entityId = this.$route?.params["id"];

    this.loadPage();
  }

  optionsUpdated(options: VDataTableOptions) {
        const {
      sortBy, sortDesc, page, itemsPerPage,
    } = options;

    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? 'desc' : 'asc'}`);

    const currentQuery = this.$route.query;
    currentQuery.sort = [currentQuery.sort].flat();

    const newQuery: Partial<Query> = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort
    };
    if (sort.length === 0) {
      delete newQuery.sort;
    }

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.replace({
      query: newQuery,
    });
  }

  async loadPage() {
    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      if (this.cancellationToken) this.cancellationToken.cancel("Reloaded list");
      this.cancellationToken = axios.CancelToken.source();

      const list = await this.HistoryService.listEntityHistory(
        this.entityType,
        this.entityId,
        pagination,
        this.cancellationToken.token);
      if (list)
      {
        this.size = list.page.size;
        this.page = list.page.number;
        this.totalItems = list.page.totalElements;
        this.items = list._embedded.audits;

        localState.setDefaultPageSize(this.size);
      }
      else {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions("No history for that entity was found");
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
      }
    } finally {
      this.loading = false;
    }
  }
}

